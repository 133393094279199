import { createSelector } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import type {
  ApiError,
  BreedConstant,
  Constant,
  FeatureTogglesConstant,
  LanguagesConstant,
  NamedEntity,
  Nil,
  SpeciesConstant,
  WplanPriceTypeConstant,
} from '@pbt/pbt-ui-components'
import { Constants, Utils } from '@pbt/pbt-ui-components'

// @ts-ignore
import * as API from '../../api'
import Features from '../../constants/features'
import type { RootState } from '../index'
import { getCurrentBusinessId } from './businesses'

export const FETCH_CONSTANTS = 'constants/FETCH_CONSTANTS'
export const FETCH_CONSTANTS_SUCCESS = 'constants/FETCH_CONSTANTS_SUCCESS'
export const FETCH_CONSTANTS_FAILURE = 'constants/FETCH_CONSTANTS_FAILURE'

export const fetchConstants = (businessId: string) => ({
  type: FETCH_CONSTANTS,
  businessId,
})
export const fetchConstantsSuccess = (constants: Constant[]) => ({
  type: FETCH_CONSTANTS_SUCCESS,
  constants,
})
export const fetchConstantsFailure = (error: ApiError) => ({
  type: FETCH_CONSTANTS_FAILURE,
  error,
})

export enum KioskNamedConstants {
  AnswerInputTypes = 'AnswerInputTypes',
  AppointmentTypes = 'AppointmentTypes',
  ContactMethod = 'ContactMethod',
  DocumentInputAction = 'DocumentInputAction',
  EventState = 'EventState',
  Gender = 'Gender',
  ReferralSources = 'ReferralSources',
  SpayedNeuteredStatus = 'SpayedNeuteredStatus',
  WplanLimitType = 'WplanLimitType',
  WplanType = 'WplanType',
}

export type KioskConstantsReducerState = {
  [key in KioskNamedConstants]: NamedEntity[]
} & {
  Breed: BreedConstant
  DocumentInputAction: NamedEntity[]
  FeatureToggles: FeatureTogglesConstant[]
  KioskAppointmentReasons: (NamedEntity & { appointmentTypeId: string })[]
  Languages: LanguagesConstant[]
  Species: SpeciesConstant[]
  WplanPriceType: WplanPriceTypeConstant[]
  error: ApiError | Nil
  isLoading: boolean
}

const INITIAL_STATE: KioskConstantsReducerState = {
  AnswerInputTypes: [],
  AppointmentTypes: [],
  Breed: {},
  ContactMethod: [],
  DocumentInputAction: [],
  EventState: [],
  FeatureToggles: [],
  Gender: [],
  KioskAppointmentReasons: [],
  Languages: [],
  ReferralSources: [],
  SpayedNeuteredStatus: [],
  Species: [],
  WplanLimitType: [],
  WplanPriceType: [],
  WplanType: [],

  error: null,
  isLoading: false,
}

export const constantsReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case FETCH_CONSTANTS:
      return { ...state, isLoading: true }
    case FETCH_CONSTANTS_SUCCESS:
      return {
        ...state,
        ...action.constants,
        isLoading: false,
      }
    case FETCH_CONSTANTS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }
    default:
      return state
  }
}

export const getConstants = (state: RootState): KioskConstantsReducerState =>
  state.constants
export const getConstantsIsLoading = (state: RootState) =>
  getConstants(state).isLoading
export const getConstantsError = (state: RootState) => getConstants(state).error

export const getAnswerInputTypes = (state: RootState) =>
  getConstants(state).AnswerInputTypes
export const getAppointmentTypes = (state: RootState) =>
  getConstants(state).AppointmentTypes
export const getBreed = (state: RootState) => getConstants(state).Breed
export const getContactMethod = (state: RootState) =>
  getConstants(state).ContactMethod
export const getDocumentInputActions = (state: RootState) =>
  getConstants(state).DocumentInputAction
export const getEventState = (state: RootState) =>
  getConstants(state).EventState
export const getFeatureToggles = (state: RootState) =>
  getConstants(state)?.FeatureToggles || []
export const getGender = (state: RootState) => getConstants(state).Gender
export const getKioskAppointmentReasons = (state: RootState) =>
  getConstants(state).KioskAppointmentReasons
export const getReferralSources = (state: RootState) =>
  getConstants(state).ReferralSources
export const getSpecies = (state: RootState) => getConstants(state).Species
export const getWellnessPlanLimitType = (state: RootState) =>
  getConstants(state).WplanLimitType
export const getWellnessPlanPriceType = (state: RootState) =>
  getConstants(state).WplanPriceType
export const getWellnessPlanType = (state: RootState) =>
  getConstants(state).WplanType
export const getLanguages = (state: RootState) =>
  getConstants(state).Languages || []

const getFeatureToggleObject = (featureName: Features | Nil) =>
  createSelector(
    getFeatureToggles,
    (FeatureToggles: Constants['FeatureToggles']) => {
      if (!featureName) {
        return null
      }

      return Utils.findConstantByName(
        featureName,
        FeatureToggles,
      ) as FeatureTogglesConstant
    },
  )

export const getFeatureToggle = (featureName: Features | Nil) =>
  createSelector<RootState, boolean>(
    getFeatureToggleObject(featureName),
    getCurrentBusinessId,
    (
      FeatureToggleObject: FeatureTogglesConstant | Nil,
      currentBusinessId: string | Nil,
    ) => {
      if (!Utils.isProduction() && window.pbt) {
        const value = window.pbt.getFTValue(featureName)

        if (!R.isNil(value)) {
          return value
        }
      }
      if (!featureName) {
        return false
      }
      if (!FeatureToggleObject) {
        return false
      }
      const { businessIds = [], enabled } = FeatureToggleObject

      const isFTBusinessEmpty = R.isEmpty(businessIds)
      const isFTEnabledForCurrentBusiness = currentBusinessId
        ? businessIds.includes(currentBusinessId)
        : false
      return isFTBusinessEmpty
        ? enabled
        : isFTEnabledForCurrentBusiness && enabled
    },
  )

export function* sagaFetchConstants({
  businessId,
}: ReturnType<typeof fetchConstants>) {
  try {
    // @ts-ignore
    const constants = yield call(API.fetchConstants, businessId)
    yield put(fetchConstantsSuccess(constants))
  } catch (error) {
    yield put(fetchConstantsFailure(error as ApiError))
  }
}

function* watchFetchConstants() {
  yield takeLatest(FETCH_CONSTANTS, sagaFetchConstants)
}

export function* constantsSaga() {
  yield all([watchFetchConstants()])
}
