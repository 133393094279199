import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { ImageScalingUtils, PatientAvatar, Utils } from '@pbt/pbt-ui-components'
import { Edit } from '@pbt/pbt-ui-components/src/icons'

import { getSpecies } from '../../../../store/duck/constants'
import {
  getMembershipSelection,
  getWellnessPlansVersion,
  SelectionType,
} from '../../../../store/duck/wellnessPlans'
import { Membership } from '../../../../types/entities/clients'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../../utils'
import KioskLinkButton from '../../../buttons/KioskLinkButton'
import MembershipTotalsLabel from '../MembershipTotalsLabel'
import {
  getEnabledPlans,
  useConvertSelectionToPlans,
  useGroupedWellnessPlans,
  // @ts-ignore
} from '../wellnessPlanUtils'

const AVATAR_SIZE = 40

const useStyles = makeStyles(
  (theme) => ({
    patientAvatar: {
      marginRight: theme.spacing(1),
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    defaultIcon: {
      fontSize: '3rem',
    },
    button: {
      fontSize: '1.4rem',
    },
    patientTotalsText: {
      color: theme.colors.secondaryText,
      fontWeight: 400,
    },
  }),
  { name: 'MembershipSummaryTable' },
)

const BORDER_STYLE = '1px solid #D8D8D8'
export const StyledMembershipSummaryTableRow = styled(Grid)(() => ({
  borderBottom: BORDER_STYLE,
  ':first-of-type': {
    borderTop: BORDER_STYLE,
  },
}))

const StyledMembershipGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  alignContent: 'center',
  borderLeft: BORDER_STYLE,
  borderRight: 'none',
  ':first-of-type': {
    borderLeft: 'none',
  },
}))

const StyledPatientContainer = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(3),
}))

const StyledPatientName = styled(Typography)(({ theme }) => ({
  color: theme.colors.primaryText,
  fontWeight: 500,
  fontSize: '1.8rem',
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  color: theme.colors.editIconColor,
}))

const StyledEnrolledPlanText = styled(Typography)(() => ({
  opacity: 0.5,
}))

const StyledEnrolledText = styled(Typography)(() => ({
  opacity: 0.5,
  fontSize: '1.2rem',
}))

export type Totals = Record<string, number>

interface MembershipSummaryTableProps {
  readonly membership: Membership
  readonly totalsPerPatient: Record<string, Totals>
}

export const MembershipSummaryTableRow = ({
  membership,
  totalsPerPatient,
}: MembershipSummaryTableProps) => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Membership'])

  const Species = useSelector(getSpecies)
  const selection = useSelector(getMembershipSelection)
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion) || {}

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  const plans = getEnabledPlans(wellnessPlansVersion)

  const groupWellnessPlans = useGroupedWellnessPlans()
  const convertSelectionToPlans = useConvertSelectionToPlans()
  const { tiers = [] } = groupWellnessPlans(plans)

  const goToFullDetails = () => {
    navigateWithQueryString({ url: '/membership/plan-details' })
  }

  const editMembership = (patientId: string) => {
    navigateWithQueryString({
      url: '/membership/sign-up',
      options: {
        replace: true,
        state: {
          patientId,
        },
      },
    })
  }

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    membership.patient.photo,
    membership.patient.photoThumbnail,
    AVATAR_SIZE,
  )
  const totalsForPatient = Object.keys(totalsPerPatient[membership.patient.id])

  const selectionsForPatient = selection.filter(
    (item: { patientId: string }) => item.patientId === membership.patient.id,
  )

  const { tiers: tiersSelection = [] } =
    convertSelectionToPlans(selectionsForPatient)

  const { tiers: existingTiers = [] } = groupWellnessPlans(
    membership.plans || [],
  )

  const patientCell = (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <StyledPatientContainer container item alignItems="center" wrap="nowrap">
        <PatientAvatar
          animal={Utils.getConstantName(membership.patient.speciesId, Species)}
          classes={{
            root: classes.patientAvatar,
            defaultIcon: classes.defaultIcon,
          }}
          src={avatarSrc}
        />
        <Dotdotdot clamp={1}>
          <StyledPatientName variant="body2">
            {membership.patient.name}
          </StyledPatientName>
        </Dotdotdot>
      </StyledPatientContainer>
      <StyledIconButton
        size="large"
        onClick={() => editMembership(membership.patient.id)}
      >
        <Edit />
      </StyledIconButton>
    </Grid>
  )

  const tierCell =
    tiersSelection.length > 0 || existingTiers.length > 0 ? (
      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>
          {tiersSelection.map((selectionItem: SelectionType) => {
            const tier = Utils.findById(selectionItem.planId, tiers)
            return (
              <Typography key={selectionItem.planId} variant="body2">
                {tier.name}
              </Typography>
            )
          })}
          {existingTiers.map((tier: SelectionType) => (
            <React.Fragment key={tier.planId}>
              <StyledEnrolledPlanText variant="body2">
                {tier.planName}
              </StyledEnrolledPlanText>
              <StyledEnrolledText variant="body2">
                ({t('Membership:ENROLLED')})
              </StyledEnrolledText>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item>
          <KioskLinkButton
            className={classes.button}
            color="secondary"
            onClick={goToFullDetails}
          >
            {t('Common:VIEW_FULL_PLAN_DETAILS')}
          </KioskLinkButton>
        </Grid>
      </Grid>
    ) : (
      <Typography variant="body2">—</Typography>
    )

  const totalsCell =
    totalsForPatient.length > 0 ? (
      <MembershipTotalsLabel
        alignItems={isMobile ? 'flex-start' : 'flex-end'}
        classes={{
          totalText: classes.patientTotalsText,
        }}
        showLabel={false}
        totals={totalsPerPatient[membership.patient.id]}
      />
    ) : (
      <Typography align={isMobile ? 'left' : 'right'} variant="body2">
        —
      </Typography>
    )

  return (
    <StyledMembershipSummaryTableRow container key={membership.patient.id}>
      <StyledMembershipGrid item pl={4} xs={3.5}>
        {patientCell}
      </StyledMembershipGrid>
      <StyledMembershipGrid item pl={1} xs={5}>
        {tierCell}
      </StyledMembershipGrid>
      <StyledMembershipGrid container item justifyContent="right" pr={3} xs={3}>
        {totalsCell}
      </StyledMembershipGrid>
    </StyledMembershipSummaryTableRow>
  )
}
