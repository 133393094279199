import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import {
  getInvoice,
  updateHasAgreedToPay,
  // @ts-ignore
} from '../../../../store/duck/finance'
import { Invoice } from '../../../../types/entities/finance'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../../utils'
import KioskScreen from '../../KioskScreen'
// @ts-ignore
import PayButtonSection from '../PayButtonSection'
import EstimateSummary from './EstimateSummary'
import EstimateTable from './EstimateTable'
import EstimateTitle from './EstimateTitle'
import MultiEstimateSummary from './MultiEstimateSummary'

const useStyles = makeStyles(
  (theme) => ({
    tableContainer: {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        maxHeight: 'calc(var(--app-height) - 278px)',
      },
      overflowY: 'auto',
      maxHeight: 'calc(var(--app-height) - 317px)',
      ...theme.constants.scrollbar,
    },
  }),
  { name: 'EstimateScreen' },
)

const EstimateScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const invoice: Invoice = useSelector(getInvoice)

  const invoices = invoice ? invoice.invoices || [invoice] : []

  const proceed = () => {
    dispatch(updateHasAgreedToPay(true))
    navigateWithQueryString({ url: '/payment/billing-address' })
  }

  return (
    <KioskScreen alignItems="flex-start" justifyContent="flex-start">
      <Grid
        container
        item
        className={classes.tableContainer}
        direction="column"
        wrap="nowrap"
      >
        {invoices.map((internalInvoice) => (
          <React.Fragment key={internalInvoice.invoiceNo}>
            <EstimateTitle invoice={internalInvoice} />
            <EstimateTable invoice={internalInvoice} />
            {invoices.length > 1 && (
              <EstimateSummary invoice={internalInvoice} />
            )}
          </React.Fragment>
        ))}
        <MultiEstimateSummary />
      </Grid>
      <PayButtonSection alignment="right" onProceed={proceed} />
    </KioskScreen>
  )
}

export default EstimateScreen
