import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Backspace } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  PhoneUtils,
  useEventListener,
  useFields,
  Validators,
} from '@pbt/pbt-ui-components'

import Features from '../../constants/features'
import { getCurrentBusiness } from '../../store/duck/businesses'
import {
  fetchClientByPhone,
  getClientsIsLoading,
  getCurrentClient,
} from '../../store/duck/clients'
import { getFeatureToggle } from '../../store/duck/constants'
// @ts-ignore
import { getIsCheckInFlow, getIsJoinWaitlistFlow } from '../../store/duck/flow'
// @ts-ignore
import { getPatientsIsLoading } from '../../store/duck/patients'
import {
  checkInForAppointment,
  getCurrentAppointment,
} from '../../store/duck/schedules'
import { useShouldDisplaySmsConsent } from '../../store/hooks/clients'
import { Appointment } from '../../types/entities/appointments'
// @ts-ignore
import { useNavigateWithQueryString } from '../../utils'
import { isPatientCheckedIn } from '../../utils/isPatientCheckedIn'
// @ts-ignore
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
// @ts-ignore
import PhoneInput from '../inputs/PhoneInput'
import KioskScreen from './KioskScreen'

type UseStylesProps = {
  buttonsColor: any
}

const useStyles = makeStyles(
  (theme) => ({
    textField: {
      maxWidth: 365,
    },
    buttonsContainer: {
      paddingTop: theme.spacing(0.5),
      maxWidth: '265px !important',
    },
    button: {
      marginBottom: theme.spacing(3),
      borderRadius: '50%',
      lineHeight: '52px',
      height: theme.spacing(8),
      width: theme.spacing(8),
      backgroundColor: theme.colors.tabLabel,
      fontSize: '4.4rem',
      color: theme.colors.profileText,
      boxShadow:
        '1px 1px 2px 0 rgba(60,56,56,0.15), 0 1px 4px 0 rgba(76,73,73,0.12)',
      '&:hover': {
        backgroundColor: theme.colors.tabLabel,
      },
    },
    nextButton: {
      fontSize: '2rem',
      textTransform: 'none',
      color: (props: UseStylesProps) =>
        props.buttonsColor ? theme.colors.profileText : undefined,
      backgroundColor: R.prop('buttonsColor'),
      '&&&&:hover': {
        backgroundColor: R.prop('buttonsColor'),
      },
    },
    buttonDisabled: {
      backgroundColor: `${theme.colors.selectedOption} !important`,
      '&:disabled': {
        color: theme.colors.profileText,
      },
    },
    backspace: {
      fontSize: '3.6rem',
    },
    input: {
      lineHeight: '1.2em',
      fontSize: '3.8rem',
    },
    inputUS: {
      textAlign: 'center',
      '&::placeholder': {
        opacity: '100%',
        color: '#000000',
      },
      '&:focus': {
        textAlign: 'left',
      },
    },
  }),
  { name: 'PhoneNumberScreen' },
)

export interface PhoneNumberScreenProps {
  readonly enableUpdateClientFlow?: boolean
  readonly failureRoute?: string
  readonly successRoute: string
}

interface PhoneHandle {
  getFormattedNumber: () => string
  getPhoneLength: () => number
}

const PhoneNumberScreen = ({
  enableUpdateClientFlow = true,
  failureRoute = '/email',
  successRoute,
}: PhoneNumberScreenProps) => {
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const isConsentFormEnabled = useSelector(
    getFeatureToggle(Features.INPUT_FORMS_DOCUMENT),
  )
  const { t } = useTranslation('Common')

  const currentBusiness = useSelector(getCurrentBusiness)
  const currentClient = useSelector(getCurrentClient)
  const appointment = useSelector(getCurrentAppointment) as Appointment
  const isCheckIn: boolean = useSelector(getIsCheckInFlow)
  const isLoading = useSelector(getClientsIsLoading)
  const isWaitlist: boolean = useSelector(getIsJoinWaitlistFlow)
  const inputRef = useRef<PhoneHandle>()

  const [isFocused, setIsFocused] = useState(false)

  const { customColorsEnabled, buttonsColor } = currentBusiness || {}

  const classes = useStyles({
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  const nextButtonLabel = t('Common:NEXT_ACTION')

  const {
    fields: { phone },
    validate,
  } = useFields([
    { name: 'phone', validators: ['required', 'phone'], initialValue: '' },
  ])

  const onCheckedIn = () => {
    navigateWithQueryString({ url: '/success' })
  }

  const callbackWhenLoadedAppointment = useCallbackWhenLoaded(
    onCheckedIn,
    getPatientsIsLoading,
  )

  const onUserFetched = () => {
    if (currentClient) {
      // TODO CVC-12872 - integrate consent forms with the 3P flow if we choose to allow the feature for 3P
      if (isConsentFormEnabled) {
        navigateWithQueryString({ url: '/consent-forms' })
      } else if (currentClient.needsUpdate && enableUpdateClientFlow) {
        navigateWithQueryString({ url: '/client-details' })
      } else if (!enableUpdateClientFlow) {
        navigateWithQueryString({ url: successRoute })
      } else if (isPatientCheckedIn(isWaitlist, isCheckIn, appointment)) {
        callbackWhenLoadedAppointment()
        dispatch(checkInForAppointment(appointment?.id, true))
      } else if (isCheckIn && !appointment.isCheckedIn) {
        navigateWithQueryString({ url: '/any-more-pets' })
      } else if (isWaitlist) {
        navigateWithQueryString({ url: '/any-more-pets' })
      } else {
        navigateWithQueryString({ url: '/select-patient' })
      }
    } else {
      navigateWithQueryString({ url: failureRoute })
    }
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onUserFetched,
    getClientsIsLoading,
  )
  const phoneLength = inputRef.current?.getPhoneLength()!
  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  const onDigitClick = (digit: string | number | JSX.Element) => {
    const previousNumber =
      phone.value || inputRef.current?.getFormattedNumber() || ''

    const newPhone = `${previousNumber}${digit}`
    if (Validators.isPhoneValid(newPhone) || newPhone.length <= phoneLength) {
      phone.setValue(newPhone)
    }
  }

  const onBackspaceClick = () => {
    validate({ silent: true })
    phone.setValue(phone.value.substring(0, phone.value.length - 1))
  }

  const onNextClick = () => {
    if (validate()) {
      callbackWhenLoaded()
      const parsedPhone = PhoneUtils.parsePhoneNumber(phone.value)

      if (isCheckIn) {
        dispatch(
          fetchClientByPhone(
            parsedPhone,
            appointment?.id,
            shouldDisplaySmsConsent,
          ),
        )
      } else {
        dispatch(
          fetchClientByPhone(parsedPhone, undefined, shouldDisplaySmsConsent),
        )
      }
    }
  }

  const onKeyDown = ({ key }: any) => {
    if (isFocused) {
      return
    }
    if (parseInt(key, 10)) {
      onDigitClick(key)
    } else if (key === 'Backspace') {
      onBackspaceClick()
    } else if (key === 'Enter') {
      onNextClick()
    }
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  useEventListener('keydown', onKeyDown)

  const buttons = [
    { label: 1, key: '1' },
    { label: 2, key: '2' },
    { label: 3, key: '3' },
    { label: 4, key: '4' },
    { label: 5, key: '5' },
    { label: 6, key: '6' },
    { label: 7, key: '7' },
    { label: 8, key: '8' },
    { label: 9, key: '9' },
    {
      label: <Backspace className={classes.backspace} />,
      key: 'backspace',
      onClick: onBackspaceClick,
    },
    { label: 0, key: '0' },
    {
      key: nextButtonLabel,
      label: nextButtonLabel,
      disabled: phone.value.length < phoneLength || isLoading,
      onClick: onNextClick,
    },
  ]

  return (
    <KioskScreen
      justifyContent="flex-start"
      title={
        isCheckIn
          ? t('Common:PLEASE_CONFIRM_YOUR_PHONE_NUMBER')
          : t('Common:PLEASE_ENTER_YOUR_PHONE_NUMBER')
      }
    >
      <Grid item>
        <PhoneInput
          autoFocus
          InputProps={{
            disableUnderline: true,
            onBlur,
            onFocus,
          }}
          className={classes.textField}
          field={phone}
          inputClassNameInt={classes.input}
          inputClassNameUS={classNames(classes.input, classes.inputUS)}
          ref={inputRef}
        />
      </Grid>
      <Grid container item className={classes.buttonsContainer}>
        {buttons.map(({ key, label, disabled = false, onClick }) => (
          <Grid item key={key} xs={4}>
            <Button
              classes={{
                disabled: classes.buttonDisabled,
                root: classNames(classes.button, {
                  [classes.nextButton]: label === nextButtonLabel,
                }),
              }}
              disableRipple={false}
              disabled={disabled}
              onClick={onClick || (() => onDigitClick(label))}
            >
              {label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </KioskScreen>
  )
}

export default PhoneNumberScreen
