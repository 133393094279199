import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Divider, Grid, Typography } from '@mui/material'
import classNames from 'classnames'
import {
  DateUtils,
  NumberUtils,
  StateLabel,
  Utils,
} from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import {
  getFinanceBalance,
  getFinancePaymentAmount,
  getIsMultiInvoicePayment,
  getPaymentIsPaid,
} from '../../../store/duck/finance'
import { getServiceFeeAmount } from '../../../utils'

const InvoicePaymentDetails = ({ invoice, classes, showRemainingBalance }) => {
  const { t } = useTranslation('Common')

  const isPaid = useSelector(getPaymentIsPaid)
  const isMultiInvoice = useSelector(getIsMultiInvoicePayment)
  const balance = useSelector(getFinanceBalance)
  const amount = useSelector(getFinancePaymentAmount)
  const business = useSelector(getCurrentBusiness)

  const {
    grandTotal,
    previousBalance,
    currentBalance,
    totalWithServiceFee: balanceTotalWithServiceFee,
    serviceFeeAmount: balanceServiceFee,
  } = balance || {}

  const { serviceFee = 0 } = business || {}

  const serviceFeeAmount = amount
    ? getServiceFeeAmount(amount, serviceFee)
    : balanceServiceFee

  const totalWithServiceFee = amount || balanceTotalWithServiceFee

  const remainingBalance = Utils.round(
    currentBalance - totalWithServiceFee + serviceFeeAmount,
    2,
  )
  return (
    <Grid
      container
      item
      className={classNames(classes.leftContainer, classes.invoiceDetails)}
      direction="c
            olumn"
      md={4}
      xs={12}
    >
      {isPaid && (
        <StateLabel success className={classes.stateLabel}>
          {t('Common:PAID')}
        </StateLabel>
      )}
      {isMultiInvoice &&
        invoice.invoices.map((childInvoice) => (
          <React.Fragment key={childInvoice.invoiceNo}>
            <Typography className={classes.text}>{`${t('Common:INVOICE')} ${
              childInvoice.invoiceNo
            }`}</Typography>
            <Grid container item justifyContent="space-between">
              <Typography className={classes.text}>
                {DateUtils.formatDate(childInvoice.creationDate)} |{' '}
                {childInvoice.patient.name}
              </Typography>
              <Typography className={classes.text}>
                {NumberUtils.formatMoney(childInvoice.amount)}
              </Typography>
            </Grid>
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      {invoice && (
        <Grid container item justifyContent="space-between">
          <Typography className={classes.text}>
            {t('Common:PREVIOUS_BALANCE')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(previousBalance)}
          </Typography>
        </Grid>
      )}
      {invoice && (
        <Grid container item justifyContent="space-between">
          <Typography className={classes.text}>
            {isMultiInvoice
              ? t('Common:GRAND_TOTAL_NUMBER_INVOICES', {
                  number: invoice.invoices.length,
                })
              : t('Common:INVOICE_TOTAL')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(grandTotal)}
          </Typography>
        </Grid>
      )}
      <Grid container item justifyContent="space-between">
        <Typography className={classes.text}>
          {t('Common:CURRENT_BALANCE')}
        </Typography>
        <Typography className={classes.text}>
          {NumberUtils.formatMoney(currentBalance)}
        </Typography>
      </Grid>
      {invoice && <Divider className={classes.divider} />}
      {serviceFeeAmount > 0 && (
        <Grid container item justifyContent="space-between">
          <Typography className={classes.text}>
            {t('Common:SERVICE_FEE')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(serviceFeeAmount)}
          </Typography>
        </Grid>
      )}
      <Grid container item justifyContent="space-between">
        <Typography className={classes.mainText}>
          {serviceFeeAmount
            ? t('Common:TOTAL_WITH_SERVICE_FEE')
            : t('Common:TOTAL')}
        </Typography>
        <Typography className={classes.mainText}>
          {NumberUtils.formatMoney(totalWithServiceFee)}
        </Typography>
      </Grid>
      {showRemainingBalance && (
        <Grid container item justifyContent="space-between">
          <Typography className={classes.text}>
            {t('Common:REMAINING_BALANCE')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(remainingBalance)}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default InvoicePaymentDetails
