import { all } from 'redux-saga/effects'

import { appointmentConfirmationSaga as appointmentConfirmation } from '../duck/appointmentConfirmation'
import { braintreeSaga as braintree } from '../duck/braintree'
import { businessesSaga as businesses } from '../duck/businesses'
import { clientsSaga as clients } from '../duck/clients'
import { constantsSaga as constants } from '../duck/constants'
import { documentsSaga as documents } from '../duck/documents'
import { documentsV2Saga as documentsV2 } from '../duck/documentsV2'
import { errorsSaga as errors } from '../duck/errors'
import { financeSaga as finance } from '../duck/finance'
import { npsSaga as nps } from '../duck/nps'
import { patientsSaga as patients } from '../duck/patients'
import { paymentsSaga as payments } from '../duck/payments'
import { questionsSaga as questions } from '../duck/questions'
import { schedulesSaga as schedules } from '../duck/schedules'
import { wellnessPlansSaga as wellnessPlans } from '../duck/wellnessPlans'
import { appointmentTypesSaga as appointmentTypes } from './appointmentTypes'

// eslint-disable-next-line func-names
export default function* () {
  yield all([
    appointmentConfirmation(),
    appointmentTypes(),
    businesses(),
    clients(),
    constants(),
    documents(),
    documentsV2(),
    errors(),
    finance(),
    nps(),
    patients(),
    payments(),
    questions(),
    schedules(),
    wellnessPlans(),
    braintree(),
  ])
}
