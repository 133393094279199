import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { DateUtils, Utils } from '@pbt/pbt-ui-components'

import {
  Invoice,
  InvoiceLineItemGroup,
} from '../../../../types/entities/finance'

const useStyles = makeStyles(
  (theme) => ({
    text: {
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      fontWeight: 500,
    },
  }),
  { name: 'EstimateTableHeader' },
)

interface EstimateGroupHeaderProps {
  className?: string
  group: InvoiceLineItemGroup
  invoice: Invoice
}

const EstimateGroupHeader = ({
  invoice,
  className,
  group,
}: EstimateGroupHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <Typography className={classNames(className, classes.text)}>
      {group.soap ? (
        <>
          {group.soap.assignedVet
            ? t('Common:APPOINTMENT_WITH_VET', {
                vet: Utils.getPersonString(group.soap.assignedVet),
              })
            : t('Common:APPOINTMENT')}
          {invoice.event?.scheduledStartDatetime
            ? ` | ${DateUtils.formatDate(
                invoice.event?.scheduledStartDatetime,
              )}`
            : ''}
        </>
      ) : (
        t('Common:OTC_SALE')
      )}
    </Typography>
  )
}

EstimateGroupHeader.propTypes = {
  className: PropTypes.string,
  group: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
}

export default EstimateGroupHeader
