import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { getInvoice, getPaymentIsPaid } from '../../../store/duck/finance'
import KioskScreen from '../KioskScreen'
import EstimatePaymentDetails from './EstimatePaymentDetails'
import InvoiceTitle from './invoice/InvoiceTitle'
import InvoicePaymentDetailsScreen from './InvoicePaymentDetails'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 1, 1),
      },
      padding: theme.spacing(3, 6, 3, 3),
    },
    rightContainer: {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
      },
    },

    text: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    mainText: {
      fontWeight: 500,
      color: theme.colors.primaryText,
      fontSize: '1.4rem',
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stateLabel: {
      width: 'fit-content',
      marginBottom: theme.spacing(1),
    },
    content: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 1),
      },
    },
  }),
  { name: 'PaymentDetailsScreen' },
)

const PaymentDetailsScreen = forwardRef(function PaymentDetailsScreen(
  { showRemainingBalance = false, children, footer, ...rest },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isPaid = useSelector(getPaymentIsPaid)

  const invoice = useSelector(getInvoice)

  return (
    <KioskScreen
      alignItems="flex-start"
      justifyContent="flex-start"
      proceedButtonLabel={t('Common:CONTINUE_TO_PAYMENT')}
      ref={ref}
      {...rest}
    >
      <InvoiceTitle invoice={invoice} />
      <Grid container item alignItems="flex-start" className={classes.root}>
        {invoice.type === 'Estimate' ? (
          <EstimatePaymentDetails invoice={invoice} />
        ) : (
          <InvoicePaymentDetailsScreen
            invoice={invoice}
            isPaid={isPaid}
            showRemainingBalance={showRemainingBalance}
          />
        )}
        <Grid
          container
          item
          xs
          className={classNames(classes.rightContainer, classes.content)}
          direction="column"
        >
          {children}
        </Grid>
      </Grid>
      {footer}
    </KioskScreen>
  )
})

PaymentDetailsScreen.propTypes = {
  children: PropTypes.any,
  footer: PropTypes.any,
  serviceFeeAmount: PropTypes.number,
  showRemainingBalance: PropTypes.bool,
}

export default PaymentDetailsScreen
