import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { NumberUtils } from '@pbt/pbt-ui-components'

const useStyles = makeStyles((theme) => ({
  leftContainer: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
  },
  invoiceDetails: {
    [theme.breakpoints.down('md')]: {
      borderBottom: theme.constants.tableBorder,
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      border: theme.constants.tableBorder,
      padding: theme.spacing(2),
      borderRadius: 2,
    },
  },
  tableCell: {
    textAlign: 'right',
    fontSize: '14px',
    paddingBottom: '4px',
    paddingTop: '4px',
    borderBottomStyle: 'hidden',
    borderColor: '#D8D8D8',
  },
  alignLeft: {
    textAlign: 'left',
  },
  depositMessageCell: {
    backgroundColor: '#F6F5F5',
    fontSize: '12px',
    paddingTop: '8px',
    borderBottomStyle: 'none',
    paddingBottom: '8px',
    borderColor: '#D8D8D8',
  },
  estimatePaymentTable: {
    tableLayout: 'auto',
    textAlign: 'right',
    maxWidth: '390px',
    maringTop: '32px',
  },
  lowAmountCell: {
    maxWidth: '120px',
  },
  taxRowCell: {
    borderBottomStyle: 'none',
  },
  tableHeaderCell: {
    borderBottomStyle: 'hidden',
    textAlign: 'right',
    borderColor: '#D8D8D8',
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '500',
    fontSize: '12px',
    color: '#918D8D',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  depositDueCell: {
    backgroundColor: '#F6F5F5',
    borderColor: '#D8D8D8',
    borderRightStyle: 'hidden',
    borderBottomStyle: 'hidden',
    paddingBottom: '4px',
    paddingTop: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  depositDueLastCell: {
    borderRightStyle: 'double',
  },
  estimatedTotalCell: {
    borderColor: '#D8D8D8',
    paddingBottom: '8px',
    paddingTop: '8px',
    borderBottomStyle: 'none',
  },
  stateLabel: {
    width: 'fit-content',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: '1.4rem',
    color: theme.colors.secondaryText,
  },
}))

const EstimatePaymentDetails = ({ invoice }) => {
  const { t } = useTranslation('Common')

  const classes = useStyles()

  const { subtotal, totalDiscount, totalTax, dueToPay } = invoice || {}

  const isSubTotalRanged = NumberUtils.isAmountObj(subtotal)
  const isTotalDiscountRanged = NumberUtils.isAmountObj(totalDiscount)
  const isTotalTaxRanged = NumberUtils.isAmountObj(totalTax)
  const isDueToPayRanged = NumberUtils.isAmountObj(dueToPay)

  const isAnyAmountRanged =
    isSubTotalRanged ||
    isTotalDiscountRanged ||
    isTotalTaxRanged ||
    isDueToPayRanged
  return (
    <table border="1" className={classes.estimatePaymentTable}>
      <tr>
        {isAnyAmountRanged ? (
          <>
            <td className={classes.tableHeaderCell} />
            <th className={classes.tableHeaderCell}>{t('Common:LOW_TOTAL')}</th>
            <th className={classes.tableHeaderCell}>
              {t('Common:HIGH_TOTAL')}
            </th>
          </>
        ) : (
          <>
            <td className={classes.tableHeaderCell} />
            <th className={classes.tableHeaderCell}> {t('Common:TOTAL')}</th>
          </>
        )}
      </tr>
      <tbody>
        <tr>
          <td className={classNames(classes.tableCell, classes.alignLeft)}>
            {t('Common:SUBTOTAL')}
          </td>
          {isSubTotalRanged ? (
            <>
              <td
                className={classNames(classes.tableCell, classes.lowAmountCell)}
              >
                {NumberUtils.formatMoney(subtotal.low)}
              </td>
              <td className={classNames(classes.tableCell)}>
                {NumberUtils.formatMoney(subtotal.high)}
              </td>
            </>
          ) : (
            <td
              className={classNames(classes.tableCell)}
              colSpan={2}
              style={{ textAlign: isAnyAmountRanged ? 'center' : 'right' }}
            >
              {NumberUtils.formatMoney(subtotal)}
            </td>
          )}
        </tr>

        <tr>
          <td className={classNames(classes.tableCell, classes.alignLeft)}>
            {t('Common:DISCOUNT')}
          </td>
          {isTotalDiscountRanged ? (
            <>
              <td
                className={classNames(classes.tableCell, classes.lowAmountCell)}
              >
                {NumberUtils.formatMoney(totalDiscount.low)}
              </td>
              <td className={classNames(classes.tableCell)}>
                {NumberUtils.formatMoney(totalDiscount.high)}
              </td>
            </>
          ) : (
            <td
              className={classes.tableCell}
              colSpan={2}
              style={{ textAlign: isAnyAmountRanged ? 'center' : 'right' }}
            >
              {NumberUtils.formatMoney(totalDiscount)}
            </td>
          )}
        </tr>

        <tr>
          <td
            className={classNames(
              classes.tableCell,
              classes.alignLeft,
              classes.taxRowCell,
            )}
          >
            {t('Common:TAX')}
          </td>
          {isTotalTaxRanged ? (
            <>
              <td className={classNames(classes.tableCell, classes.taxRowCell)}>
                {NumberUtils.formatMoney(totalTax.high)}
              </td>
              <td
                className={classNames(
                  classes.tableCell,
                  classes.lowAmountCell,
                  classes.taxRowCell,
                )}
              >
                {NumberUtils.formatMoney(totalTax.low)}
              </td>
            </>
          ) : (
            <td
              className={classNames(classes.tableCell, classes.taxRowCell)}
              colSpan={2}
              style={{ textAlign: isAnyAmountRanged ? 'center' : 'right' }}
            >
              {NumberUtils.formatMoney(totalTax)}
            </td>
          )}
        </tr>
        <tr>
          <td
            className={classNames(
              classes.tableCell,
              classes.estimatedTotalCell,
              classes.alignLeft,
            )}
          >
            {t('Common:ESTIMATED_TOTAL')}
          </td>
          {isSubTotalRanged ? (
            <>
              <td
                className={classNames(
                  classes.tableCell,
                  classes.lowAmountCell,
                  classes.estimatedTotalCell,
                )}
              >
                {NumberUtils.formatMoney(subtotal.low)}
              </td>
              <td
                className={classNames(
                  classes.tableCell,
                  classes.estimatedTotalCell,
                )}
              >
                {NumberUtils.formatMoney(subtotal.high)}
              </td>
            </>
          ) : (
            <td
              className={classNames(
                classes.tableCell,
                classes.estimatedTotalCell,
              )}
              colSpan={2}
              style={{ textAlign: isAnyAmountRanged ? 'center' : 'right' }}
            >
              {NumberUtils.formatMoney(subtotal)}
            </td>
          )}
        </tr>

        <tr>
          <td className={classes.depositDueCell}>
            {t('Common:DEPOSIT_DUE_TODAY')}
          </td>
          <td
            className={classNames(
              classes.depositDueCell,
              classes.depositDueLastCell,
            )}
            colSpan={isAnyAmountRanged ? 2 : 1}
            style={{ textAlign: isAnyAmountRanged ? 'center' : 'right' }}
          >
            {NumberUtils.formatMoney(dueToPay)}
          </td>
        </tr>

        <tr>
          <td className={classes.depositMessageCell} colSpan={3}>
            {t('Common:DEPOSIT_TOTAL_MESSAGE')}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default EstimatePaymentDetails
