import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { BaseUser, DateUtils, Text, Utils } from '@pbt/pbt-ui-components'

import { getCurrentClient } from '../../../../store/duck/clients'
import { Invoice } from '../../../../types/entities/finance'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(2),
      },
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
  }),
  { name: 'EstimateTitle' },
)

interface EstimateTitleProps {
  readonly className?: string
  readonly invoice: Invoice
}

const EstimateTitle = ({ invoice, className }: EstimateTitleProps) => {
  const classes = useStyles()
  const client = useSelector(getCurrentClient)
  const { t } = useTranslation('Common')

  const { type, invoiceNo, patient, creationDate, invoices } = invoice || {}
  const isMultiEstimate = invoices?.length > 0

  return (
    <Text
      strong
      className={classNames(className, classes.title)}
      variant="subheading2"
    >
      {isMultiEstimate
        ? t('Common:MULTIPLE_INVOICES')
        : invoice
        ? `${type} ${invoiceNo} ${DateUtils.formatDate(creationDate)} ${
            patient ? `| ${patient.name}` : ''
          }`
        : Utils.getPersonString(client as BaseUser)}
    </Text>
  )
}

export default EstimateTitle
