import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { AmountObj, NumberUtils } from '@pbt/pbt-ui-components'

import {
  Invoice,
  InvoiceLineItem,
  InvoiceLineItemSoap,
} from '../../../../types/entities/finance'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      padding: theme.spacing(1, 1),
      borderRight: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    groupedCell: {
      borderBottom: 'none',
    },
    lastNestedCell: {
      borderBottom: 'none',
    },
    declinedCell: {
      color: theme.colors.tabLabel,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    boldCell: {
      fontWeight: 500,
    },
    nameCell: {
      paddingLeft: theme.spacing(3),
    },
    nestedCell: {
      paddingLeft: theme.spacing(5),
    },
    subTotalCell: {
      minWidth: 90,
      maxWidth: 200,
    },
    taxAmountCell: {
      minWidth: 85,
    },
    bodyRow: {
      borderTop: theme.constants.tabBorder,
    },
    evenRow: {
      backgroundColor: theme.colors.tableEvenItem,
    },
    oddRow: {
      backgroundColor: theme.colors.tableBackground,
    },
    nestedRow: {
      borderTop: 'none',
    },
    lastNestedRow: {
      borderBottom: `1px solid ${theme.colors.userPickerBorder}`,
    },
    groupedBodyRow: {
      borderTop: `1px solid ${theme.colors.userPickerBorder}`,
      borderBottom: `1px solid ${theme.colors.userPickerBorder}`,
    },
    groupedBodyRowExpanded: {
      borderBottom: 'none',
    },
    nameContainer: {
      flex: 1,
    },
    nameContainerGrouped: {
      cursor: 'pointer',
    },
    caret: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'EstimateTableRow' },
)

interface EstimateTableRowProps {
  index?: number
  invoice: Invoice
  isExpanded?: boolean
  item: InvoiceLineItem
  lastNested?: boolean
  nested?: boolean
  onExpandChange?: (group?: string, soap?: InvoiceLineItemSoap) => void
  soap?: InvoiceLineItemSoap
}

const EstimateTableRow = ({
  soap,
  lastNested,
  nested,
  invoice,
  item,
  isExpanded,
  onExpandChange,
  index = 0,
}: EstimateTableRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isEvenRow = index % 2 === 0
  const isDeclined = item.declined
  const isGrouped = (item.items?.length ?? 0) > 0
  const { taxAmount, subTotal } = item

  return (
    <>
      <TableRow
        className={classNames(classes.bodyRow, {
          [classes.evenRow]: isEvenRow,
          [classes.oddRow]: !isEvenRow,
          [classes.groupedBodyRow]: isGrouped,
          [classes.groupedBodyRowExpanded]: isExpanded,
          [classes.nestedRow]: nested,
          [classes.lastNestedRow]: lastNested,
        })}
      >
        <TableCell
          className={classNames(classes.tableCell, classes.nameCell, {
            [classes.nestedCell]: nested,
            [classes.declinedCell]: isDeclined,
            [classes.groupedCell]: isGrouped,
            [classes.lastNestedCell]: lastNested,
          })}
          colSpan={isGrouped ? 1 : 0}
        >
          <Grid
            container
            item
            alignItems="center"
            className={classNames(classes.nameContainer, {
              [classes.nameContainerGrouped]: isGrouped,
            })}
            onClick={() => {
              if (isGrouped && onExpandChange) {
                onExpandChange(item.groupName, soap)
              }
            }}
          >
            {isGrouped &&
              (isExpanded ? (
                <ExpandLess className={classes.caret} />
              ) : (
                <ExpandMore className={classes.caret} />
              ))}
            {isGrouped ? item.groupName : item.name}
          </Grid>
        </TableCell>
        <TableCell
          className={classNames(classes.tableCell, {
            [classes.groupedCell]: isGrouped,
            [classes.declinedCell]: isDeclined,
            [classes.lastNestedCell]: lastNested,
          })}
        >
          {isDeclined
            ? `(${t('Common:DECLINED')})`
            : !isGrouped && NumberUtils.isAmountObj(item.quantity)
            ? `${item.quantity.low} - ${item.quantity.high}`
            : (item.quantity as number)}
        </TableCell>
        <TableCell
          className={classNames(classes.tableCell, {
            [classes.groupedCell]: isGrouped,
            [classes.lastNestedCell]: lastNested,
          })}
        >
          {!isDeclined &&
            (item.discountPerc
              ? NumberUtils.toPercentFormat(item.discountPerc, 2)
              : item.discount || item.discountAmount
              ? NumberUtils.isAmountObj(item.discount ?? item.discountAmount)
                ? NumberUtils.getAmountObjRange(
                    (item.discount ?? item.discountAmount) as AmountObj,
                  )
                : NumberUtils.formatMoney(item.discount ?? item.discountAmount)
              : '')}
        </TableCell>
        <TableCell
          align="right"
          className={classNames(classes.tableCell, classes.taxAmountCell, {
            [classes.groupedCell]: isGrouped,
            [classes.lastNestedCell]: lastNested,
          })}
        >
          {!isDeclined &&
            (taxAmount
              ? NumberUtils.isAmountObj(taxAmount)
                ? NumberUtils.getAmountObjRange(taxAmount)
                : NumberUtils.formatMoney(taxAmount)
              : '')}
        </TableCell>
        <TableCell
          align="right"
          className={classNames(
            classes.tableCell,
            classes.boldCell,
            classes.subTotalCell,
            {
              [classes.groupedCell]: isGrouped,
              [classes.lastNestedCell]: lastNested,
            },
          )}
        >
          {!isDeclined && NumberUtils.isAmountObj(subTotal)
            ? NumberUtils.getAmountObjRange(subTotal)
            : NumberUtils.formatMoney(subTotal)}
        </TableCell>
      </TableRow>
      {isExpanded &&
        item.items &&
        item.items.map((childItem: InvoiceLineItem, childIndex: number) => (
          <EstimateTableRow
            nested
            index={index}
            invoice={invoice}
            item={childItem}
            key={childItem.id || childItem.priceId}
            lastNested={childIndex === item.items!.length - 1}
          />
        ))}
    </>
  )
}

EstimateTableRow.propTypes = {
  index: PropTypes.number,
  invoice: PropTypes.object,
  isExpanded: PropTypes.bool,
  item: PropTypes.object.isRequired,
  lastNested: PropTypes.bool,
  nested: PropTypes.bool,
  onExpandChange: PropTypes.func,
  soap: PropTypes.object,
}

export default EstimateTableRow
